import React, { useRef, useEffect } from "react";
import Layout from "../components/Layout";

const CookiePolicy = () => {
  const cookiePolicyContainer = useRef(null);

  useEffect(() => {
    const currentScriptTag =
      cookiePolicyContainer.current.querySelector("script");

    if (!currentScriptTag) {
      const cookiePolicyScriptTag = document.createElement("script");
      cookiePolicyScriptTag.id = "CookiePolicy";
      cookiePolicyScriptTag.src =
        "https://policy.app.cookieinformation.com/cid.js";
      cookiePolicyScriptTag.dataset.culture = "EN";
      cookiePolicyScriptTag.type = "text/javascript";
      cookiePolicyContainer.current.appendChild(cookiePolicyScriptTag);

      // NOT WORKING
      // const cookieConsentScriptTag = document.createElement("script");
      // cookieConsentScriptTag.id = "CookieConsent";
      // cookieConsentScriptTag.src =
      //   "https://policy.app.cookieinformation.com/uc.js";
      // cookieConsentScriptTag.dataset.culture = "EN";
      // cookieConsentScriptTag.type = "text/javascript";

      // cookiePolicyContainer.current.appendChild(cookieConsentScriptTag);
    }
  }, [cookiePolicyContainer]);

  useEffect(() => {
    window.CookieInformation?.loadConsent();
  }, []);

  // The cookie policy script will fail on localhost,
  // so this page will appear empty.
  return (
    <Layout title="Cookie Policy">
      <div ref={cookiePolicyContainer} />
      <div id="cicc-template" />
    </Layout>
  );
};

export default CookiePolicy;
